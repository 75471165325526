<template>
    <div style="background-color: #fff">
        <div class="pt-3">
            <search @condition="condition" :fields="searchFields">
                <el-button @click="addUser" v-access="{url:baseUrl,method:'POST'}" size="medium" slot="add"
                           icon="el-icon-plus" type="primary">新建用户
                </el-button>
            </search>
        </div>
        <div class="mt-3">
            <my-table :data="tableData" :fields="showFields">
                <el-table-column
                    slot="operation"
                    label="操作"
                >
                    <template slot-scope="scope">
                        <el-button
                            size="mini"
                            @click="editUser(scope.$index)" v-access="{url:baseUrl,method:'PUT'}"
                            icon="el-icon-edit"></el-button>
                        <el-button
                            size="mini"
                            type="danger"
                            @click="deleteUser(scope.$index,scope.row.user_id)" v-access="{url:baseUrl,method:'DELETE'}"
                            icon="el-icon-delete"></el-button>
                    </template>
                </el-table-column>
            </my-table>
        </div>
        <div class="pb-3 mt-3">
            <el-pagination
                @size-change="sizeChange"
                @current-change="pageChange"
                :current-page="page.pageNumber"
                :page-sizes="[10, 15, 30, 50]"
                :page-size="page.pageSize"
                :background="true"
                layout="sizes,prev, pager,next,total"
                :total="page.total">
            </el-pagination>
        </div>
        <form-dialog @closeFormDialog="submit" :title="form.title" :show="form.show" :fields="form.fields"
                     :rules="form.rules" :data="form.data"></form-dialog>
    </div>
</template>
<script>
import F from '@/fields/fields';
import search from "@/components/common/search";

export default {
    name: "userSystem",
    components: {
        search
    },
    created() {
        this.$axios.get('/manage/user/systemGroup', {
            params: {
                pageSize: 1000
            }
        }).then(group => {
            let user_group = [];
            group.data.data.map(v => {
                user_group.push({label: v.group_title, value: v.group_id});
            });
            this.$store.commit('setDict', {user_group: user_group});
        });
    },
    mounted() {

    },
    data: function () {
        return {
            baseUrl: '/big/user/index',
            page: {
                search: {},
                sort: {},
                total: 1,
                pageSize: 10,
                pageNumber: 1,
            },
            userGroup: [],
            tableData: [],
            form: {
                action_name: '',
                show: false,
                title: '',
                data: {},
                fields: [],
                rules: {},
            }
        }
    },
    computed: {
        searchFields: function () {
            let fields = F.search('systemUser', 'search');
            return fields;
        },
        showFields: function () {
            return F.show('systemUser', 'show');
        },
    },
    methods: {
        condition: function (search) {
            this.page.search = search;
            this.page.pageNumber = 1;
            this.search();
        },
        search: function () {
            this.$com.get(this.baseUrl, {
                params: this.page,
            }).then(data => {
                this.page.total = data.data.total;
                this.page.pageNumber = data.data.pageNumber;
                this.page.pageSize = data.data.pageSize;
                this.tableData = data.data.data;
            });
        },
        sortChange: function (column) {
            if (!column.order) return;
            this.page.sort = {prop: column.prop, order: column.order};
            this.search();
        },
        sizeChange: function (size) {
            this.page.pageSize = size;
            this.search();
        },
        pageChange: function (page) {
            this.page.pageNumber = page;
            this.search();
        },
        filterPlatform(value, row) {
            return row.tag === value;
        },
        submit: function (data) {
            // this.con(data);
            if (data.type === 'cancel') {
                this.form.show = false;
                return;
            }
            switch (this.actionName) {
                case 'editUser':
                    this.$com.put(this.baseUrl, data.data).then(userData => {
                        this.form.show = false;
                        this.$message.success('保存成功!');
                        this.tableData.splice(this.index, 1, userData.data);
                    });
                    break;
                case 'addUser':
                    this.$com.post(this.baseUrl, data.data).then(userData => {
                        this.page.total++;
                        this.$message.success('创建成功!');
                        this.form.show = false;
                        this.tableData.unshift(userData.data);
                    });
                    break;
            }

        },
        editUser: function (index) {
            let data = JSON.parse(JSON.stringify(this.tableData[index]));
            data.password = '';
            let form = F.get('systemUser', 'edit_user', data);
            form.fields.map(v => {
                switch (v.name) {
                    case 'username':
                        if (this.tableData[index].user_id == '1') v.disabled = true;
                        break;
                }
            });
            this.actionName = 'editUser';
            this.form = form;
            this.index = index;
        },
        addUser: function () {
            let form = F.get('systemUser', 'add_user');
            form.fields.map(v => {
                switch (v.name) {
                    case 'group_id':
                        v.options = this.tools.toOption(this.userGroup, 'group_id', 'group_title');
                        break;
                }
            });
            this.actionName = 'addUser';
            this.form = form;
        },
        deleteUser: function (index, id) {
            this.$confirm('你真的要删除这个用户吗?', '操作确认').then(() => {
                this.$com.delete(this.baseUrl + '/' + id).then(() => {
                    this.tableData.splice(index, 1);
                    this.page.total--;
                    this.$message.success('删除成功!');
                });
            });
        }
    }
}
</script>

<style scoped>

</style>
